/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()

  const { currentUser } = useAuth()

  return (
    <>
      {(() => {
        if (currentUser?.roles.find(indivRole => indivRole.id !== 1)) {
          return (
            <React.Fragment>
              <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/general/gen025.svg'
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
              />
            </React.Fragment>
          )
        }
      })()}

      {(() => {
        if (currentUser?.roles.find(indivRole => indivRole.id !== 11)) {
          return (
            <React.Fragment>
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Application</span>
                </div>
              </div>

              <AsideMenuItemWithSub
                to='/application/home'
                title='Home'
                icon='/media/icons/duotune/general/gen001.svg'
                expand={true}
              >
                <AsideMenuItem to='/application/home/timeclock' title='Timeclock' hasBullet={true} />
                <AsideMenuItem to='/application/home/schedule' title='Schedule' hasBullet={true} />
                <AsideMenuItem to='/application/home/suppliers' title='Suppliers' hasBullet={true} />
                <AsideMenuItem to='/application/home/accessories' title='Accessories' hasBullet={true} />
                <AsideMenuItem to='/application/home/despatch-eta' title='Despatch-eta' hasBullet={true} />
                <AsideMenuItem to='/application/home/costings' title='Costings' hasBullet={true} />
              </AsideMenuItemWithSub>

              <AsideMenuItem
                to='/application/help/messages'
                icon='/media/icons/duotune/communication/com003.svg'
                title='Help'
              />

              <AsideMenuItem
                to='/application/safety/reports'
                title='Safety'
                icon='/media/icons/duotune/arrows/arr017.svg'
              />

              <AsideMenuItemWithSub
                to='/crafted/widgets'
                title='More'
                icon='/media/icons/duotune/general/gen052.svg'
              >
                <AsideMenuItem to='/crafted/widgets/lists' title='Submit an idea' hasBullet={true} />
                <AsideMenuItem to='/crafted/widgets/feeds' title='Styx Mill Website' hasBullet={true} />
              </AsideMenuItemWithSub>
            </React.Fragment>
          )
        }
      })()}

      {(() => {
        if (currentUser?.roles.find(indivRole => indivRole.id === 1)) {
          return (
            <React.Fragment>
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Adminstration</span>
                </div>
              </div>

              <AsideMenuItem
                to='/administration/user-management/users'
                icon='/media/icons/duotune/communication/com006.svg'
                title='Users'
              />

              <AsideMenuItem
                to='/administration/users-timesheets/timesheets'
                icon='/media/icons/duotune/general/gen013.svg'
                title='Timesheets'
              />

              <AsideMenuItem
                to='/administration/user-roles/roles-list'
                icon='/media/icons/duotune/general/gen022.svg'
                title='Roles'
              />

              <AsideMenuItem
                to='/administration/leaves/leave-requests'
                icon='/media/icons/duotune/general/gen006.svg'
                title='Leaves'
              />

              <AsideMenuItemWithSub
                to='/administration/products'
                title='Products'
                icon='/media/icons/duotune/general/gen017.svg'
                expand={true}
              >
                <AsideMenuItem to='/administration/products/products-lists' title='Products Lists' hasBullet={true} />
                <AsideMenuItem to='/administration/products/materials-list' title='Raw Materials' hasBullet={true} />
                <AsideMenuItem to='/administration/products/labour-variables' title='Labour Variables' hasBullet={true} />
              </AsideMenuItemWithSub>
            </React.Fragment>
          )
        }
      })()}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
