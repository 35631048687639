export const QUERIES = {
  USERS_LIST: 'users-list',
}

export const CountryPhoneCodes = [
  {
		"name": {
			"en": "Afghanistan",
		},
		"dial_code": "+93",
		"code": "AF",
		"flag": "🇦🇫"
	},
	{
		"name": {
			"en": "Albania",
		},
		"dial_code": "+355",
		"code": "AL",
		"flag": "🇦🇱"
	},
	{
		"name": {
			"en": "Algeria",
		},
		"dial_code": "+213",
		"code": "DZ",
		"flag": "🇩🇿"
	},
	{
		"name": {
			"en": "American Samoa",
		},
		"dial_code": "+1684",
		"code": "AS",
		"flag": "🇦🇸"
	},
	{
		"name": {
			"en": "Andorra",
		},
		"dial_code": "+376",
		"code": "AD",
		"flag": "🇦🇩"
	},
	{
		"name": {
			"en": "Angola",
		},
		"dial_code": "+244",
		"code": "AO",
		"flag": "🇦🇴"
	},
	{
		"name": {
			"en": "Anguilla",
		},
		"dial_code": "+1264",
		"code": "AI",
		"flag": "🇦🇮"
	},
	{
		"name": {
			"en": "Antarctica",
		},
		"dial_code": "+672",
		"code": "AQ",
		"flag": "🇦🇶"
	},
	{
		"name": {
			"en": "Antigua and Barbuda",
		},
		"dial_code": "+1268",
		"code": "AG",
		"flag": "🇦🇬"
	},
	{
		"name": {
			"en": "Argentina",
		},
		"dial_code": "+54",
		"code": "AR",
		"flag": "🇦🇷"
	},
	{
		"name": {
			"en": "Armenia",
		},
		"dial_code": "+374",
		"code": "AM",
		"flag": "🇦🇲"
	},
	{
		"name": {
			"en": "Aruba",
		},
		"dial_code": "+297",
		"code": "AW",
		"flag": "🇦🇼"
	},
	{
		"name": {
			"en": "Australia",
		},
		"dial_code": "+61",
		"code": "AU",
		"flag": "🇦🇺"
	},
	{
		"name": {
			"en": "Austria",
		},
		"dial_code": "+43",
		"code": "AT",
		"flag": "🇦🇹"
	},
	{
		"name": {
			"en": "Azerbaijan",
		},
		"dial_code": "+994",
		"code": "AZ",
		"flag": "🇦🇿"
	},
	{
		"name": {
			"en": "Bahamas",
		},
		"dial_code": "+1242",
		"code": "BS",
		"flag": "🇧🇸"
	},
	{
		"name": {
			"en": "Bahrain",
		},
		"dial_code": "+973",
		"code": "BH",
		"flag": "🇧🇭"
	},
	{
		"name": {
			"en": "Bangladesh",
		},
		"dial_code": "+880",
		"code": "BD",
		"flag": "🇧🇩"
	},
	{
		"name": {
			"en": "Barbados",
		},
		"dial_code": "+1246",
		"code": "BB",
		"flag": "🇧🇧"
	},
	{
		"name": {
			"en": "Belarus",
		},
		"dial_code": "+375",
		"code": "BY",
		"flag": "🇧🇾"
	},
	{
		"name": {
			"en": "Belgium",
		},
		"dial_code": "+32",
		"code": "BE",
		"flag": "🇧🇪"
	},
	{
		"name": {
			"en": "Belize",
		},
		"dial_code": "+501",
		"code": "BZ",
		"flag": "🇧🇿"
	},
	{
		"name": {
			"en": "Benin",
		},
		"dial_code": "+229",
		"code": "BJ",
		"flag": "🇧🇯"
	},
	{
		"name": {
			"en": "Bermuda",
		},
		"dial_code": "+1441",
		"code": "BM",
		"flag": "🇧🇲"
	},
	{
		"name": {
			"en": "Bhutan",
		},
		"dial_code": "+975",
		"code": "BT",
		"flag": "🇧🇹"
	},
	{
		"name": {
			"en": "Bolivia",
		},
		"dial_code": "+591",
		"code": "BO",
		"flag": "🇧🇴"
	},
	{
		"name": {
			"en": "Bosnia and Herzegovina",
		},
		"dial_code": "+387",
		"code": "BA",
		"flag": "🇧🇦"
	},
	{
		"name": {
			"en": "Botswana",
		},
		"dial_code": "+267",
		"code": "BW",
		"flag": "🇧🇼"
	},
	{
		"name": {
			"en": "Brazil",
		},
		"dial_code": "+55",
		"code": "BR",
		"flag": "🇧🇷"
	},
	{
		"name": {
			"en": "British Indian Ocean Territory",
		},
		"dial_code": "+246",
		"code": "IO",
		"flag": "🇮🇴"
	},
	{
		"name": {
			"en": "Brunei Darussalam",
		},
		"dial_code": "+673",
		"code": "BN",
		"flag": "🇧🇳"
	},
	{
		"name": {
			"en": "Bulgaria",
		},
		"dial_code": "+359",
		"code": "BG",
		"flag": "🇧🇬"
	},
	{
		"name": {
			"en": "Burkina Faso",
		},
		"dial_code": "+226",
		"code": "BF",
		"flag": "🇧🇫"
	},
	{
		"name": {
			"en": "Burundi",
		},
		"dial_code": "+257",
		"code": "BI",
		"flag": "🇧🇮"
	},
	{
		"name": {
			"en": "Cambodia",
		},
		"dial_code": "+855",
		"code": "KH",
		"flag": "🇰🇭"
	},
	{
		"name": {
			"en": "Cameroon",
		},
		"dial_code": "+237",
		"code": "CM",
		"flag": "🇨🇲"
	},
	{
		"name": {
			"en": "Canada",
		},
		"dial_code": "+1",
		"code": "CA",
		"flag": "🇨🇦"
	},
	{
		"name": {
			"en": "Cape Verde",
		},
		"dial_code": "+238",
		"code": "CV",
		"flag": "🇨🇻"
	},
	{
		"name": {
			"en": "Cayman Islands",
		},
		"dial_code": "+345",
		"code": "KY",
		"flag": "🇰🇾"
	},
	{
		"name": {
			"en": "Central African Republic",
		},
		"dial_code": "+236",
		"code": "CF",
		"flag": "🇨🇫"
	},
	{
		"name": {
			"en": "Chad",
		},
		"dial_code": "+235",
		"code": "TD",
		"flag": "🇹🇩"
	},
	{
		"name": {
			"en": "Chile",
		},
		"dial_code": "+56",
		"code": "CL",
		"flag": "🇨🇱"
	},
	{
		"name": {
			"en": "China",
		},
		"dial_code": "+86",
		"code": "CN",
		"flag": "🇨🇳"
	},
	{
		"name": {
			"en": "Christmas Island",
		},
		"dial_code": "+61",
		"code": "CX",
		"flag": "🇨🇽"
	},
	{
		"name": {
			"en": "Cocos (Keeling) Islands",
		},
		"dial_code": "+61",
		"code": "CC",
		"flag": "🇨🇨"
	},
	{
		"name": {
			"en": "Colombia",
		},
		"dial_code": "+57",
		"code": "CO",
		"flag": "🇨🇴"
	},
	{
		"name": {
			"en": "Comoros",
		},
		"dial_code": "+269",
		"code": "KM",
		"flag": "🇰🇲"
	},
	{
		"name": {
			"en": "Congo",
		},
		"dial_code": "+242",
		"code": "CG",
		"flag": "🇨🇬"
	},
	{
		"name": {
			"en": "Congo, The Democratic Republic of the",
		},
		"dial_code": "+243",
		"code": "CD",
		"flag": "🇨🇩"
	},
	{
		"name": {
			"en": "Cook Islands",
		},
		"dial_code": "+682",
		"code": "CK",
		"flag": "🇨🇰"
	},
	{
		"name": {
			"en": "Costa Rica",
		},
		"dial_code": "+506",
		"code": "CR",
		"flag": "🇨🇷"
	},
	{
		"name": {
			"en": "Cote d'Ivoire",
		},
		"dial_code": "+225",
		"code": "CI",
		"flag": "🇨🇮"
	},
	{
		"name": {
			"en": "Croatia",
		},
		"dial_code": "+385",
		"code": "HR",
		"flag": "🇭🇷"
	},
	{
		"name": {
			"en": "Cuba",
		},
		"dial_code": "+53",
		"code": "CU",
		"flag": "🇨🇺"
	},
	{
		"name": {
			"en": "Cyprus",
		},
		"dial_code": "+537",
		"code": "CY",
		"flag": "🇨🇾"
	},
	{
		"name": {
			"en": "Czech Republic",
		},
		"dial_code": "+420",
		"code": "CZ",
		"flag": "🇨🇿"
	},
	{
		"name": {
			"en": "Denmark",
		},
		"dial_code": "+45",
		"code": "DK",
		"flag": "🇩🇰"
	},
	{
		"name": {
			"en": "Djibouti",
		},
		"dial_code": "+253",
		"code": "DJ",
		"flag": "🇩🇯"
	},
	{
		"name": {
			"en": "Dominica",
		},
		"dial_code": "+1767",
		"code": "DM",
		"flag": "🇩🇲"
	},
	{
		"name": {
			"en": "Dominican Republic",
		},
		"dial_code": "+1849",
		"code": "DO",
		"flag": "🇩🇴"
	},
	{
		"name": {
			"en": "Ecuador",
		},
		"dial_code": "+593",
		"code": "EC",
		"flag": "🇪🇨"
	},
	{
		"name": {
			"en": "Egypt",
		},
		"dial_code": "+20",
		"code": "EG",
		"flag": "🇪🇬"
	},
	{
		"name": {
			"en": "El Salvador",
		},
		"dial_code": "+503",
		"code": "SV",
		"flag": "🇸🇻"
	},
	{
		"name": {
			"en": "Equatorial Guinea",
		},
		"dial_code": "+240",
		"code": "GQ",
		"flag": "🇬🇶"
	},
	{
		"name": {
			"en": "Eritrea",
		},
		"dial_code": "+291",
		"code": "ER",
		"flag": "🇪🇷"
	},
	{
		"name": {
			"en": "Estonia",
		},
		"dial_code": "+372",
		"code": "EE",
		"flag": "🇪🇪"
	},
	{
		"name": {
			"en": "Ethiopia",
		},
		"dial_code": "+251",
		"code": "ET",
		"flag": "🇪🇹"
	},
	{
		"name": {
			"en": "Falkland Islands (Malvinas)",
		},
		"dial_code": "+500",
		"code": "FK",
		"flag": "🇫🇰"
	},
	{
		"name": {
			"en": "Faroe Islands",
		},
		"dial_code": "+298",
		"code": "FO",
		"flag": "🇫🇴"
	},
	{
		"name": {
			"en": "Fiji",
		},
		"dial_code": "+679",
		"code": "FJ",
		"flag": "🇫🇯"
	},
	{
		"name": {
			"en": "Finland",
		},
		"dial_code": "+358",
		"code": "FI",
		"flag": "🇫🇮"
	},
	{
		"name": {
			"en": "France",
		},
		"dial_code": "+33",
		"code": "FR",
		"flag": "🇫🇷"
	},
	{
		"name": {
			"en": "French Guiana",
		},
		"dial_code": "+594",
		"code": "GF",
		"flag": "🇬🇫"
	},
	{
		"name": {
			"en": "French Polynesia",
		},
		"dial_code": "+689",
		"code": "PF",
		"flag": "🇵🇫"
	},
	{
		"name": {
			"en": "Gabon",
		},
		"dial_code": "+241",
		"code": "GA",
		"flag": "🇬🇦"
	},
	{
		"name": {
			"en": "Gambia",
		},
		"dial_code": "+220",
		"code": "GM",
		"flag": "🇬🇲"
	},
	{
		"name": {
			"en": "Georgia",
		},
		"dial_code": "+995",
		"code": "GE",
		"flag": "🇬🇪"
	},
	{
		"name": {
			"en": "Germany",
		},
		"dial_code": "+49",
		"code": "DE",
		"flag": "🇩🇪"
	},
	{
		"name": {
			"en": "Ghana",
		},
		"dial_code": "+233",
		"code": "GH",
		"flag": "🇬🇭"
	},
	{
		"name": {
			"en": "Gibraltar",
		},
		"dial_code": "+350",
		"code": "GI",
		"flag": "🇬🇮"
	},
	{
		"name": {
			"en": "Greece",
		},
		"dial_code": "+30",
		"code": "GR",
		"flag": "🇬🇷"
	},
	{
		"name": {
			"en": "Greenland",
		},
		"dial_code": "+299",
		"code": "GL",
		"flag": "🇬🇱"
	},
	{
		"name": {
			"en": "Grenada",
		},
		"dial_code": "+1473",
		"code": "GD",
		"flag": "🇬🇩"
	},
	{
		"name": {
			"en": "Guadeloupe",
		},
		"dial_code": "+590",
		"code": "GP",
		"flag": "🇬🇵"
	},
	{
		"name": {
			"en": "Guam",
		},
		"dial_code": "+1671",
		"code": "GU",
		"flag": "🇬🇺"
	},
	{
		"name": {
			"en": "Guatemala",
		},
		"dial_code": "+502",
		"code": "GT",
		"flag": "🇬🇹"
	},
	{
		"name": {
			"en": "Guernsey",
		},
		"dial_code": "+44",
		"code": "GG",
		"flag": "🇬🇬"
	},
	{
		"name": {
			"en": "Guinea",
		},
		"dial_code": "+224",
		"code": "GN",
		"flag": "🇬🇳"
	},
	{
		"name": {
			"en": "Guinea-Bissau",
		},
		"dial_code": "+245",
		"code": "GW",
		"flag": "🇬🇼"
	},
	{
		"name": {
			"en": "Guyana",
		},
		"dial_code": "+595",
		"code": "GY",
		"flag": "🇬🇾"
	},
	{
		"name": {
			"en": "Haiti",
		},
		"dial_code": "+509",
		"code": "HT",
		"flag": "🇭🇹"
	},
	{
		"name": {
			"en": "Holy See (Vatican City State)",
		},
		"dial_code": "+379",
		"code": "VA",
		"flag": "🇻🇦"
	},
	{
		"name": {
			"en": "Honduras",
		},
		"dial_code": "+504",
		"code": "HN",
		"flag": "🇭🇳"
	},
	{
		"name": {
			"en": "Hong Kong",
		},
		"dial_code": "+852",
		"code": "HK",
		"flag": "🇭🇰"
	},
	{
		"name": {
			"en": "Hungary",
		},
		"dial_code": "+36",
		"code": "HU",
		"flag": "🇭🇺"
	},
	{
		"name": {
			"en": "Iceland",
		},
		"dial_code": "+354",
		"code": "IS",
		"flag": "🇮🇸"
	},
	{
		"name": {
			"en": "India",
		},
		"dial_code": "+91",
		"code": "IN",
		"flag": "🇮🇳"
	},
	{
		"name": {
			"en": "Indonesia",
		},
		"dial_code": "+62",
		"code": "ID",
		"flag": "🇮🇩"
	},
	{
		"name": {
			"en": "Iran, Islamic Republic of",
		},
		"dial_code": "+98",
		"code": "IR",
		"flag": "🇮🇷"
	},
	{
		"name": {
			"en": "Iraq",
		},
		"dial_code": "+964",
		"code": "IQ",
		"flag": "🇮🇶"
	},
	{
		"name": {
			"en": "Ireland",
		},
		"dial_code": "+353",
		"code": "IE",
		"flag": "🇮🇪"
	},
	{
		"name": {
			"en": "Isle of Man",
		},
		"dial_code": "+44",
		"code": "IM",
		"flag": "🇮🇲"
	},
	{
		"name": {
			"en": "Israel",
		},
		"dial_code": "+972",
		"code": "IL",
		"flag": "🇮🇱"
	},
	{
		"name": {
			"en": "Italy",
		},
		"dial_code": "+39",
		"code": "IT",
		"flag": "🇮🇹"
	},
	{
		"name": {
			"en": "Jamaica",
		},
		"dial_code": "+1876",
		"code": "JM",
		"flag": "🇯🇲"
	},
	{
		"name": {
			"en": "Japan",
		},
		"dial_code": "+81",
		"code": "JP",
		"flag": "🇯🇵"
	},
	{
		"name": {
			"en": "Jersey",
		},
		"dial_code": "+44",
		"code": "JE",
		"flag": "🇯🇪"
	},
	{
		"name": {
			"en": "Jordan",
		},
		"dial_code": "+962",
		"code": "JO",
		"flag": "🇯🇴"
	},
	{
		"name": {
			"en": "Kazakhstan",
		},
		"dial_code": "+77",
		"code": "KZ",
		"flag": "🇰🇿"
	},
	{
		"name": {
			"en": "Kenya",
		},
		"dial_code": "+254",
		"code": "KE",
		"flag": "🇰🇪"
	},
	{
		"name": {
			"en": "Kiribati",
		},
		"dial_code": "+686",
		"code": "KI",
		"flag": "🇰🇮"
	},
	{
		"name": {
			"en": "Korea, Democratic People's Republic of",
		},
		"dial_code": "+850",
		"code": "KP",
		"flag": "🇰🇵"
	},
	{
		"name": {
			"en": "Korea, Republic of",
		},
		"dial_code": "+82",
		"code": "KR",
		"flag": "🇰🇷"
	},
	{
		"name": {
			"en": "Kuwait",
		},
		"dial_code": "+965",
		"code": "KW",
		"flag": "🇰🇼"
	},
	{
		"name": {
			"en": "Kyrgyzstan",
		},
		"dial_code": "+996",
		"code": "KG",
		"flag": "🇰🇬"
	},
	{
		"name": {
			"en": "Lao People's Democratic Republic",
		},
		"dial_code": "+856",
		"code": "LA",
		"flag": "🇱🇦"
	},
	{
		"name": {
			"en": "Latvia",
		},
		"dial_code": "+371",
		"code": "LV",
		"flag": "🇱🇻"
	},
	{
		"name": {
			"en": "Lebanon",
		},
		"dial_code": "+961",
		"code": "LB",
		"flag": "🇱🇧"
	},
	{
		"name": {
			"en": "Lesotho",
		},
		"dial_code": "+266",
		"code": "LS",
		"flag": "🇱🇸"
	},
	{
		"name": {
			"en": "Liberia",
		},
		"dial_code": "+231",
		"code": "LR",
		"flag": "🇱🇷"
	},
	{
		"name": {
			"en": "Libyan Arab Jamahiriya",
		},
		"dial_code": "+218",
		"code": "LY",
		"flag": "🇱🇾"
	},
	{
		"name": {
			"en": "Liechtenstein",
		},
		"dial_code": "+423",
		"code": "LI",
		"flag": "🇱🇮"
	},
	{
		"name": {
			"en": "Lithuania",
		},
		"dial_code": "+370",
		"code": "LT",
		"flag": "🇱🇹"
	},
	{
		"name": {
			"en": "Luxembourg",
		},
		"dial_code": "+352",
		"code": "LU",
		"flag": "🇱🇺"
	},
	{
		"name": {
			"en": "Macao",
		},
		"dial_code": "+853",
		"code": "MO",
		"flag": "🇲🇴"
	},
	{
		"name": {
			"en": "Macedonia, The Former Yugoslav Republic of",
		},
		"dial_code": "+389",
		"code": "MK",
		"flag": "🇲🇰"
	},
	{
		"name": {
			"en": "Madagascar",
		},
		"dial_code": "+261",
		"code": "MG",
		"flag": "🇲🇬"
	},
	{
		"name": {
			"en": "Malawi",
		},
		"dial_code": "+265",
		"code": "MW",
		"flag": "🇲🇼"
	},
	{
		"name": {
			"en": "Malaysia",
		},
		"dial_code": "+60",
		"code": "MY",
		"flag": "🇲🇾"
	},
	{
		"name": {
			"en": "Maldives",
		},
		"dial_code": "+960",
		"code": "MV",
		"flag": "🇲🇻"
	},
	{
		"name": {
			"en": "Mali",
		},
		"dial_code": "+223",
		"code": "ML",
		"flag": "🇲🇱"
	},
	{
		"name": {
			"en": "Malta",
		},
		"dial_code": "+356",
		"code": "MT",
		"flag": "🇲🇹"
	},
	{
		"name": {
			"en": "Marshall Islands",
		},
		"dial_code": "+692",
		"code": "MH",
		"flag": "🇲🇭"
	},
	{
		"name": {
			"en": "Martinique",
		},
		"dial_code": "+596",
		"code": "MQ",
		"flag": "🇲🇶"
	},
	{
		"name": {
			"en": "Mauritania",
		},
		"dial_code": "+222",
		"code": "MR",
		"flag": "🇲🇷"
	},
	{
		"name": {
			"en": "Mauritius",
		},
		"dial_code": "+230",
		"code": "MU",
		"flag": "🇲🇺"
	},
	{
		"name": {
			"en": "Mayotte",
		},
		"dial_code": "+262",
		"code": "YT",
		"flag": "🇾🇹"
	},
	{
		"name": {
			"en": "Mexico",
		},
		"dial_code": "+52",
		"code": "MX",
		"flag": "🇲🇽"
	},
	{
		"name": {
			"en": "Micronesia, Federated States of",
		},
		"dial_code": "+691",
		"code": "FM",
		"flag": "🇫🇲"
	},
	{
		"name": {
			"en": "Moldova, Republic of",
		},
		"dial_code": "+373",
		"code": "MD",
		"flag": "🇲🇩"
	},
	{
		"name": {
			"en": "Monaco",
		},
		"dial_code": "+377",
		"code": "MC",
		"flag": "🇲🇨"
	},
	{
		"name": {
			"en": "Mongolia",
		},
		"dial_code": "+976",
		"code": "MN",
		"flag": "🇲🇳"
	},
	{
		"name": {
			"en": "Montenegro",
		},
		"dial_code": "+382",
		"code": "ME",
		"flag": "🇲🇪"
	},
	{
		"name": {
			"en": "Montserrat",
		},
		"dial_code": "+1664",
		"code": "MS",
		"flag": "🇲🇸"
	},
	{
		"name": {
			"en": "Morocco",
		},
		"dial_code": "+212",
		"code": "MA",
		"flag": "🇲🇦"
	},
	{
		"name": {
			"en": "Mozambique",
		},
		"dial_code": "+258",
		"code": "MZ",
		"flag": "🇲🇿"
	},
	{
		"name": {
			"en": "Myanmar",
		},
		"dial_code": "+95",
		"code": "MM",
		"flag": "🇲🇲"
	},
	{
		"name": {
			"en": "Namibia",
		},
		"dial_code": "+264",
		"code": "NA",
		"flag": "🇳🇦"
	},
	{
		"name": {
			"en": "Nauru",
		},
		"dial_code": "+674",
		"code": "NR",
		"flag": "🇳🇷"
	},
	{
		"name": {
			"en": "Nepal",
		},
		"dial_code": "+977",
		"code": "NP",
		"flag": "🇳🇵"
	},
	{
		"name": {
			"en": "Netherlands",
		},
		"dial_code": "+31",
		"code": "NL",
		"flag": "🇳🇱"
	},
	{
		"name": {
			"en": "Netherlands Antilles",
		},
		"dial_code": "+599",
		"code": "AN",
		"flag": "🇦🇳"
	},
	{
		"name": {
			"en": "New Caledonia",
		},
		"dial_code": "+687",
		"code": "NC",
		"flag": "🇳🇨"
	},
	{
		"name": {
			"en": "New Zealand",
		},
		"dial_code": "+64",
		"code": "NZ",
		"flag": "🇳🇿"
	},
	{
		"name": {
			"en": "Nicaragua",
		},
		"dial_code": "+505",
		"code": "NI",
		"flag": "🇳🇮"
	},
	{
		"name": {
			"en": "Niger",
		},
		"dial_code": "+227",
		"code": "NE",
		"flag": "🇳🇪"
	},
	{
		"name": {
			"en": "Nigeria",
		},
		"dial_code": "+234",
		"code": "NG",
		"flag": "🇳🇬"
	},
	{
		"name": {
			"en": "Niue",
		},
		"dial_code": "+683",
		"code": "NU",
		"flag": "🇳🇺"
	},
	{
		"name": {
			"en": "Norfolk Island",
		},
		"dial_code": "+672",
		"code": "NF",
		"flag": "🇳🇫"
	},
	{
		"name": {
			"en": "Northern Mariana Islands",
		},
		"dial_code": "+1670",
		"code": "MP",
		"flag": "🇲🇵"
	},
	{
		"name": {
			"en": "Norway",
		},
		"dial_code": "+47",
		"code": "NO",
		"flag": "🇳🇴"
	},
	{
		"name": {
			"en": "Oman",
		},
		"dial_code": "+968",
		"code": "OM",
		"flag": "🇴🇲"
	},
	{
		"name": {
			"en": "Pakistan",
		},
		"dial_code": "+92",
		"code": "PK",
		"flag": "🇵🇰"
	},
	{
		"name": {
			"en": "Palau",
		},
		"dial_code": "+680",
		"code": "PW",
		"flag": "🇵🇼"
	},
	{
		"name": {
			"en": "Palestinian Territory, Occupied",
		},
		"dial_code": "+970",
		"code": "PS",
		"flag": "🇵🇸"
	},
	{
		"name": {
			"en": "Panama",
		},
		"dial_code": "+507",
		"code": "PA",
		"flag": "🇵🇦"
	},
	{
		"name": {
			"en": "Papua New Guinea",
		},
		"dial_code": "+675",
		"code": "PG",
		"flag": "🇵🇬"
	},
	{
		"name": {
			"en": "Paraguay",
		},
		"dial_code": "+595",
		"code": "PY",
		"flag": "🇵🇾"
	},
	{
		"name": {
			"en": "Peru",
		},
		"dial_code": "+51",
		"code": "PE",
		"flag": "🇵🇪"
	},
	{
		"name": {
			"en": "Philippines",
		},
		"dial_code": "+63",
		"code": "PH",
		"flag": "🇵🇭"
	},
	{
		"name": {
			"en": "Pitcairn",
		},
		"dial_code": "+872",
		"code": "PN",
		"flag": "🇵🇳"
	},
	{
		"name": {
			"en": "Poland",
		},
		"dial_code": "+48",
		"code": "PL",
		"flag": "🇵🇱"
	},
	{
		"name": {
			"en": "Portugal",
		},
		"dial_code": "+351",
		"code": "PT",
		"flag": "🇵🇹"
	},
	{
		"name": {
			"en": "Puerto Rico",
		},
		"dial_code": "+1939",
		"code": "PR",
		"flag": "🇵🇷"
	},
	{
		"name": {
			"en": "Qatar",
		},
		"dial_code": "+974",
		"code": "QA",
		"flag": "🇶🇦"
	},
	{
		"name": {
			"en": "Romania",
		},
		"dial_code": "+40",
		"code": "RO",
		"flag": "🇷🇴"
	},
	{
		"name": {
			"en": "Russia",
		},
		"dial_code": "+7",
		"flag": "🇷🇺"
	},
	{
		"name": {
			"en": "Rwanda",
		},
		"dial_code": "+250",
		"code": "RW",
		"flag": "🇷🇼"
	},
	{
		"name": {
			"en": "Réunion",
		},
		"dial_code": "+262",
		"code": "RE",
		"flag": "🇷🇪"
	},
	{
		"name": {
			"en": "Saint Barthélemy",
		},
		"dial_code": "+590",
		"code": "BL",
		"flag": "🇧🇱"
	},
	{
		"name": {
			"en": "Saint Helena, Ascension and Tristan Da Cunha",
		},
		"dial_code": "+290",
		"code": "SH",
		"flag": "🇸🇭"
	},
	{
		"name": {
			"en": "Saint Kitts and Nevis",
		},
		"dial_code": "+1869",
		"code": "KN",
		"flag": "🇰🇳"
	},
	{
		"name": {
			"en": "Saint Lucia",
		},
		"dial_code": "+1758",
		"code": "LC",
		"flag": "🇱🇨"
	},
	{
		"name": {
			"en": "Saint Martin",
		},
		"dial_code": "+590",
		"code": "MF",
		"flag": "🇲🇫"
	},
	{
		"name": {
			"en": "Saint Pierre and Miquelon",
		},
		"dial_code": "+508",
		"code": "PM",
		"flag": "🇵🇲"
	},
	{
		"name": {
			"en": "Saint Vincent and the Grenadines",
		},
		"dial_code": "+1784",
		"code": "VC",
		"flag": "🇻🇨"
	},
	{
		"name": {
			"en": "Samoa",
		},
		"dial_code": "+685",
		"code": "WS",
		"flag": "🇼🇸"
	},
	{
		"name": {
			"en": "San Marino",
		},
		"dial_code": "+378",
		"code": "SM",
		"flag": "🇸🇲"
	},
	{
		"name": {
			"en": "Sao Tome and Principe",
		},
		"dial_code": "+239",
		"code": "ST",
		"flag": "🇸🇹"
	},
	{
		"name": {
			"en": "Saudi Arabia",
		},
		"dial_code": "+966",
		"code": "SA",
		"flag": "🇸🇦"
	},
	{
		"name": {
			"en": "Senegal",
		},
		"dial_code": "+221",
		"code": "SN",
		"flag": "🇸🇳"
	},
	{
		"name": {
			"en": "Serbia",
		},
		"dial_code": "+381",
		"code": "RS",
		"flag": "🇷🇸"
	},
	{
		"name": {
			"en": "Seychelles",
		},
		"dial_code": "+248",
		"code": "SC",
		"flag": "🇸🇨"
	},
	{
		"name": {
			"en": "Sierra Leone",
		},
		"dial_code": "+232",
		"code": "SL",
		"flag": "🇸🇱"
	},
	{
		"name": {
			"en": "Singapore",
		},
		"dial_code": "+65",
		"code": "SG",
		"flag": "🇸🇬"
	},
	{
		"name": {
			"en": "Slovakia",
		},
		"dial_code": "+421",
		"code": "SK",
		"flag": "🇸🇰"
	},
	{
		"name": {
			"en": "Slovenia",
		},
		"dial_code": "+386",
		"code": "SI",
		"flag": "🇸🇮"
	},
	{
		"name": {
			"en": "Solomon Islands",
		},
		"dial_code": "+677",
		"code": "SB",
		"flag": "🇸🇧"
	},
	{
		"name": {
			"en": "Somalia",
		},
		"dial_code": "+252",
		"code": "SO",
		"flag": "🇸🇴"
	},
	{
		"name": {
			"en": "South Africa",
		},
		"dial_code": "+27",
		"code": "ZA",
		"flag": "🇿🇦"
	},
	{
		"name": {
			"en": "South Georgia and the South Sandwich Islands",
		},
		"dial_code": "+500",
		"code": "GS",
		"flag": "🇬🇸"
	},
	{
		"name": {
			"en": "Spain",
		},
		"dial_code": "+34",
		"code": "ES",
		"flag": "🇪🇸"
	},
	{
		"name": {
			"en": "Sri Lanka",
		},
		"dial_code": "+94",
		"code": "LK",
		"flag": "🇱🇰"
	},
	{
		"name": {
			"en": "Sudan",
		},
		"dial_code": "+249",
		"code": "SD",
		"flag": "🇸🇩"
	},
	{
		"name": {
			"en": "Suriname",
		},
		"dial_code": "+597",
		"code": "SR",
		"flag": "🇸🇷"
	},
	{
		"name": {
			"en": "Svalbard and Jan Mayen",
		},
		"dial_code": "+47",
		"code": "SJ",
		"flag": "🇸🇯"
	},
	{
		"name": {
			"en": "Swaziland",
		},
		"dial_code": "+268",
		"code": "SZ",
		"flag": "🇸🇿"
	},
	{
		"name": {
			"en": "Sweden",
		},
		"dial_code": "+46",
		"code": "SE",
		"flag": "🇸🇪"
	},
	{
		"name": {
			"en": "Switzerland",
		},
		"dial_code": "+41",
		"code": "CH",
		"flag": "🇨🇭"
	},
	{
		"name": {
			"en": "Syrian Arab Republic",
		},
		"dial_code": "+963",
		"code": "SY",
		"flag": "🇸🇾"
	},
	{
		"name": {
			"en": "Taiwan, Province of China",
		},
		"dial_code": "+886",
		"code": "TW",
		"flag": "🇹🇼"
	},
	{
		"name": {
			"en": "Tajikistan",
		},
		"dial_code": "+992",
		"code": "TJ",
		"flag": "🇹🇯"
	},
	{
		"name": {
			"en": "Tanzania, United Republic of",
		},
		"dial_code": "+255",
		"code": "TZ",
		"flag": "🇹🇿"
	},
	{
		"name": {
			"en": "Thailand",
		},
		"dial_code": "+66",
		"code": "TH",
		"flag": "🇹🇭"
	},
	{
		"name": {
			"en": "Timor-Leste",
		},
		"dial_code": "+670",
		"code": "TL",
		"flag": "🇹🇱"
	},
	{
		"name": {
			"en": "Togo",
		},
		"dial_code": "+228",
		"code": "TG",
		"flag": "🇹🇬"
	},
	{
		"name": {
			"en": "Tokelau",
		},
		"dial_code": "+690",
		"code": "TK",
		"flag": "🇹🇰"
	},
	{
		"name": {
			"en": "Tonga",
		},
		"dial_code": "+676",
		"code": "TO",
		"flag": "🇹🇴"
	},
	{
		"name": {
			"en": "Trinidad and Tobago",
		},
		"dial_code": "+1868",
		"code": "TT",
		"flag": "🇹🇹"
	},
	{
		"name": {
			"en": "Tunisia",
		},
		"dial_code": "+216",
		"code": "TN",
		"flag": "🇹🇳"
	},
	{
		"name": {
			"en": "Turkey",
		},
		"dial_code": "+90",
		"code": "TR",
		"flag": "🇹🇷"
	},
	{
		"name": {
			"en": "Turkmenistan",
		},
		"dial_code": "+993",
		"code": "TM",
		"flag": "🇹🇲"
	},
	{
		"name": {
			"en": "Turks and Caicos Islands",
		},
		"dial_code": "+1649",
		"code": "TC",
		"flag": "🇹🇨"
	},
	{
		"name": {
			"en": "Tuvalu",
		},
		"dial_code": "+688",
		"code": "TV",
		"flag": "🇹🇻"
	},
	{
		"name": {
			"en": "Uganda",
		},
		"dial_code": "+256",
		"code": "UG",
		"flag": "🇺🇬"
	},
	{
		"name": {
			"en": "Ukraine",
		},
		"dial_code": "+380",
		"code": "UA",
		"flag": "🇺🇦"
	},
	{
		"name": {
			"en": "United Arab Emirates",
		},
		"dial_code": "+971",
		"code": "AE",
		"flag": "🇦🇪"
	},
	{
		"name": {
			"en": "United Kingdom",
		},
		"dial_code": "+44",
		"code": "GB",
		"flag": "🇬🇧"
	},
	{
		"name": {
			"en": "United States",
		},
		"dial_code": "+1",
		"code": "US",
		"flag": "🇺🇸"
	},
	{
		"name": {
			"en": "Uruguay",
		},
		"dial_code": "+598",
		"code": "UY",
		"flag": "🇺🇾"
	},
	{
		"name": {
			"en": "Uzbekistan",
		},
		"dial_code": "+998",
		"code": "UZ",
		"flag": "🇺🇿"
	},
	{
		"name": {
			"en": "Vanuatu",
		},
		"dial_code": "+678",
		"code": "VU",
		"flag": "🇻🇺"
	},
	{
		"name": {
			"en": "Venezuela, Bolivarian Republic of",
		},
		"dial_code": "+58",
		"code": "VE",
		"flag": "🇻🇪"
	},
	{
		"name": {
			"en": "Viet Nam",
		},
		"dial_code": "+84",
		"code": "VN",
		"flag": "🇻🇳"
	},
	{
		"name": {
			"en": "Virgin Islands, British",
		},
		"dial_code": "+1284",
		"code": "VG",
		"flag": "🇻🇬"
	},
	{
		"name": {
			"en": "Virgin Islands, U.S.",
		},
		"dial_code": "+1340",
		"code": "VI",
		"flag": "🇻🇮"
	},
	{
		"name": {
			"en": "Wallis and Futuna",
		},
		"dial_code": "+681",
		"code": "WF",
		"flag": "🇼🇫"
	},
	{
		"name": {
			"en": "Yemen",
		},
		"dial_code": "+967",
		"code": "YE",
		"flag": "🇾🇪"
	},
	{
		"name": {
			"en": "Zambia",
		},
		"dial_code": "+260",
		"code": "ZM",
		"flag": "🇿🇲"
	},
	{
		"name": {
			"en": "Zimbabwe",
		},
		"dial_code": "+263",
		"code": "ZW",
		"flag": "🇿🇼"
	},
	{
		"name": {
			"en": "Åland Islands",
		},
		"dial_code": "+358",
		"code": "AX",
		"flag": "🇦🇽"
	}
];
