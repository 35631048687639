import axios from "axios"
import { TypeTimesheet, TypeUser } from "./_models";

// Types

// URLs
const API_URL = `${process.env.REACT_APP_API_URL}/dashboard`;

interface ReponseGetTimesheetAdminDashboardData {
  data: {
    users: TypeUser[];
    timesheets: TypeTimesheet[];
    startOfWeek: string;
  }
}

export function getTimesheetAdminDashboardData(user?: TypeUser) {
  return axios.get<ReponseGetTimesheetAdminDashboardData>(`${API_URL}/timesheet-admin?user_id=${user?.id}`);
}
