import {useIntl} from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import dayjs, { Dayjs } from 'dayjs'
import {
  StatisticsWidget5,
} from '../../../_metronic/partials/widgets'
import { Dropdown1 } from '../../../_metronic/partials'
import { useAuth } from '../../modules/auth'
import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'

// Types
import { TypeUser } from './_core/_models'
import { getTimesheetAdminDashboardData } from './_core/_request'

const DashboardPage = () => {
  const [init, setInit] = useState<boolean>(false);
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [appStateChecked, setAppStateChecked] = useState<boolean>(false);
  const [users, setUsers] = useState<TypeUser[]>([]);

  const { currentUser } = useAuth()

  const fetchData = useCallback(
    async (initialize: boolean) => {
      try {
        const {status, data} = await getTimesheetAdminDashboardData(users[selectedUserIndex]);

        if (status === 200) {
          setUsers(data.data.users);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setInit(true)
      }
    },
    [],
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(dayjs());

      let secondsCounter = 0;

      // check if user is clocked-in every 5 mins
      if (secondsCounter === 300) {
        // fetchData(false);
        secondsCounter = 0;
      } else {
        secondsCounter += 1;
      }
    }, 1000);

    if (!appStateChecked) {
      setAppStateChecked(true);
    } else {
      fetchData(true);
    }
  
    return () => {
      clearInterval(interval);
    };
  }, [appStateChecked, fetchData]);

  if (!init) {
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  }

  const selectedUser = users[selectedUserIndex];

  if (currentUser?.roles.find(indivRole => indivRole.id === 11)) {
    return (
      <>
        {/* begin::Row */}
        <div className='row g-5 g-xl-8 mb-8'>
          <div className='col-xl-4'>
            <div className="card">
              {/* begin::Body */}
              <div className='card-body' style={{minHeight: 225}}>
                <a href='#' className='card-title fw-bold text-muted text-hover-primary fs-4'>
                  User
                </a>
    
                {/* begin::Input */}
                <select
                  onChange={e => {
                    setSelectedUserIndex(parseInt(e.target.value));
                  }}
                  className="form-control form-control-solid text-start form-select form-select-solid mt-3"
                  // disabled={isSubmitting || values.restDay}
                  name='user'
                  value={selectedUserIndex}
                >
                  {users.map((indivUser, i) => {
                    return (
                      <option
                        key={i}
                        value={i}
                      >
                        {indivUser.firstname} {indivUser.lastname}
                      </option>
                    )
                  })}
                </select>
                {/* end::Input */}

                <div className="d-flex flex-row align-items-center justify-content-between mt-8 mb-6">
                  <div className='d-flex align-items-center'>
                    <a href='/' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {date.format('ddd MMM DD, YYYY @ hh:mm:ss A')}
                    </a>
                  </div>

                  <button
                    type='button'
                    className='btn btn-success text-black fw-bold'
                  >
                    Clock-in
                  </button>
                </div>
              </div>
              {/* end::Body */}
            </div>
          </div>
          
          {Boolean(selectedUser) && (
            <React.Fragment>
              <div className='col-xl-4'>
                <div className='card'>
                  <div className='card-body' style={{minHeight: 225}}>
                    <div className='d-flex flex-wrap flex-sm-nowrap'>
                      <div className='me-7'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                          <div
                            className={clsx(
                              'symbol-label fs-1',
                            )}
                          >
                            {selectedUser.firstname[0]}{selectedUser.lastname[0]}
                          </div>
                        </div>
                      </div>

                      <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center mb-2'>
                              <a href='/' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                {selectedUser.firstname} {selectedUser.lastname}
                              </a>
                            </div>

                            <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                              <a
                                href='/'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/communication/com006.svg'
                                  className='svg-icon-4 me-1'
                                />
                                {selectedUser.role}
                              </a>
                              <a
                                href='/'
                                className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/communication/com011.svg'
                                  className='svg-icon-4 me-1'
                                />
                                {selectedUser?.email}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-xl-4'>
                <div className="card">
                  {/* begin::Body */}
                  <div className='card-body' style={{minHeight: 225}}>
                    <div className="d-flex flex-row justify-content-between mb-5">
                      <a href='#' className='card-title fw-bold text-muted fs-4'>
                        Today's Summary
                      </a>
                      <p className='card-title fw-bold text-muted fs-4'>
                        {date.format('ddd. MMM DD, YYYY')}
                      </p>
                    </div>

                    <div className="mb-5">
                      <span className="fs-4 text-gray-800 fw-bold">Schedule: </span>
                      <span className="fs-4 text-gray-800">07:00 am - 05:00 pm (30 minutes break)</span>
                    </div>

                    <div className="mb-5">
                      <span className="fs-4 text-gray-800 fw-bold">Logged time: </span>
                      <span className="fs-4 text-gray-800">Please clocked-in</span>
                    </div>

                    <div className="mb-5">
                      <span className="fs-4 text-gray-800 fw-bold">Worked hours: </span>
                      <span className="fs-4 text-gray-800">0.00 Hours</span>
                    </div>
                  </div>
                  {/* end::Body */}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-12'>
            <div className="card">
              {/* begin::Body */}
              <div className='card-body'>
                <div className="mb-5 d-flex flex-row justify-content-between">
                  <a href='#' className='card-title fw-bold text-muted text-hover-primary fs-4'>
                    Logged days
                  </a>

                  <div>
                    <span className="text-gray-800 fs-4 fw-bold">Payroll Week: </span>
                    <span className="text-gray-800 fs-4">Thu. Feb. 22 - Wed. Feb. 28</span>
                  </div>
                </div>
                
                <table className='table table-row-dashed fs-6 gy-5 dataTable no-footer'>
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      <th style={{ width: '15%' }}>Date</th>
                      <th style={{ width: '25%' }}>Logs</th>
                      <th style={{ width: '25%' }}>Job Orders</th>
                      <th style={{ width: '35%' }}>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>{dayjs('2024-02-22').format('ddd MMMM DD, YYYY')}</p>
                      </td>

                      <td>
                        <ul className='mb-3'>
                          <li className='mb-5'>
                            <span className='d-flex align-items-center gap-2'>
                              06:55 AM - 12:40 PM
                            </span>
                          </li>

                          <li className='mb-5'>
                            <span className='d-flex align-items-center gap-2'>
                              01:02 PM - 05:25 PM
                            </span>
                          </li>
                        </ul>

                        <span className='fw-bold'>Sub-total: </span>
                        <span>8 hrs</span>
                      </td>
                      <td>
                        <button
                          className='d-block p-2 px-3 btn btn-light-primary'
                          onClick={() => {
                            // setShowModalAddJobForm(true);
                            // setSelectedTimesheet(indivUserTimesheet);
                          }}>
                          Add a job
                        </button>
                      </td>
                      <td>
                        <ul>
                          <li className='mb-5'>
                            <p className='m-0 fw-bold'>{dayjs('2024-02-22 13:00:00').format('ddd MMM. DD, YYYY hh:mm A')}</p>
                            <p className='m-0' style={{ whiteSpace: 'pre-wrap' }}>
                              Fri Feb. 23, 2024 02:04 AM

                              - CLOCK-IN modified from 07:33 AM to 07:34 AM, 
                              Changes approved by Bethany Mitchell
                            </p>
                          </li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p>{dayjs('2024-02-22').format('ddd MMMM DD, YYYY')}</p>
                      </td>

                      <td>
                        <ul className='mb-3'>
                          <li className='mb-5'>
                            <span className='d-flex align-items-center gap-2'>
                              06:55 AM - 12:40 PM
                            </span>
                          </li>

                          <li className='mb-5'>
                            <span className='d-flex align-items-center gap-2'>
                              01:02 PM - 05:25 PM
                            </span>
                          </li>
                        </ul>

                        <span className='fw-bold'>Sub-total: </span>
                        <span>8 hrs</span>
                      </td>
                      <td>
                        <button
                          className='d-block p-2 px-3 btn btn-light-primary'
                          onClick={() => {
                            // setShowModalAddJobForm(true);
                            // setSelectedTimesheet(indivUserTimesheet);
                          }}>
                          Add a job
                        </button>
                      </td>
                      <td>
                        <ul>
                          <li className='mb-5'>
                            <p className='m-0 fw-bold'>{dayjs('2024-02-22 13:00:00').format('ddd MMM. DD, YYYY hh:mm A')}</p>
                            <p className='m-0' style={{ whiteSpace: 'pre-wrap' }}>
                              Fri Feb. 23, 2024 02:04 AM

                              - CLOCK-IN modified from 07:33 AM to 07:34 AM, 
                              Changes approved by Bethany Mitchell
                            </p>
                          </li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p>{dayjs('2024-02-22').format('ddd MMMM DD, YYYY')}</p>
                      </td>

                      <td>
                        <ul className='mb-3'>
                          <li className='mb-5'>
                            <span className='d-flex align-items-center gap-2'>
                              06:55 AM - 12:40 PM
                            </span>
                          </li>

                          <li className='mb-5'>
                            <span className='d-flex align-items-center gap-2'>
                              01:02 PM - 05:25 PM
                            </span>
                          </li>
                        </ul>

                        <span className='fw-bold'>Sub-total: </span>
                        <span>8 hrs</span>
                      </td>
                      <td>
                        <button
                          className='d-block p-2 px-3 btn btn-light-primary'
                          onClick={() => {
                            // setShowModalAddJobForm(true);
                            // setSelectedTimesheet(indivUserTimesheet);
                          }}>
                          Add a job
                        </button>
                      </td>
                      <td>
                        <ul>
                          <li className='mb-5'>
                            <p className='m-0 fw-bold'>{dayjs('2024-02-22 13:00:00').format('ddd MMM. DD, YYYY hh:mm A')}</p>
                            <p className='m-0' style={{ whiteSpace: 'pre-wrap' }}>
                              Fri Feb. 23, 2024 02:04 AM

                              - CLOCK-IN modified from 07:33 AM to 07:34 AM, 
                              Changes approved by Bethany Mitchell
                            </p>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
        {/* end::Row */}
      </>
    )
  } else {
    return (
      <>
        {/* begin::Row */}
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4'>
            <div
              className={`card bgi-no-repeat card-xl-stretch mb-xl-8`}
              style={{
                backgroundPosition: 'right top',
                backgroundSize: '30% auto',
                backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-2.svg')})`,
              }}
            >
              {/* begin::Body */}
              <div className='card-body'>
                <a href='#' className='card-title fw-bold text-muted text-hover-primary fs-4'>
                  Timeclock
                </a>
    
                <div className='fw-bold text-primary my-6'>{dayjs(new Date()).format('ddd, MMM. DD, YYYY')}</div>
    
                <p
                  className='text-dark-75 fw-semibold fs-5 m-0 mb-2'
                  dangerouslySetInnerHTML={{__html: 'Please clock-in to start your day.'}}
                ></p>
    
                <button
                  type='button'
                  className='btn btn-warning text-black'
                >
                  Clock-in
                </button>
              </div>
              {/* end::Body */}
            </div>
          </div>
    
          <div className='col-xl-4'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/communication/com003.svg'
              color='primary'
              iconColor='white'
              title='Need some help?'
              description='Have a chat with your supervisor.'
              titleColor='white'
              descriptionColor='white'
            />
          </div>
    
          <div className='col-xl-4'>
            <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/arrows/arr017.svg'
              color='dark'
              iconColor='gray-100'
              title='Safety First'
              description='File a report in-case an incident occurred.'
              titleColor='gray-100'
              descriptionColor='gray-100'
            />
          </div>
        </div>
        {/* end::Row */}
    
        {/* begin::Row */}
        <div className='row g-5 g-xl-8'>
          {/* begin::Col */}
          <div className='col-xl-4'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold text-dark'>Work Schedule</span>
                  <span className='text-muted mt-1 fw-semibold fs-7'><strong>Hours per week: </strong>40 Hours</span>
                </h3>
    
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                  </button>
                  <Dropdown1 />
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
    
              {/* begin::Body */}
              <div className='card-body'>
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-danger text-danger'>
                      Sun
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Text */}
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                      Rest day
                    </a>
                    <span className='text-muted fw-semibold'>No work</span>
                  </div>
                  {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-primary text-primary'>
                      Mon
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Text */}
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                      7:00 AM - 3:00 PM
                    </a>
                    <span className='text-muted fw-semibold'>8 Hours</span>
                  </div>
                  {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-primary text-primary'>
                      Tue
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Text */}
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                      7:00 AM - 3:00 PM
                    </a>
                    <span className='text-muted fw-semibold'>8 Hours</span>
                  </div>
                  {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-primary text-primary'>
                      Wed
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Text */}
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                      7:00 AM - 5:00 PM
                    </a>
                    <span className='text-muted fw-semibold'>10 Hours</span>
                  </div>
                  {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-primary text-primary'>
                      Thu
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Text */}
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                      7:00 AM - 1:00 PM
                    </a>
                    <span className='text-muted fw-semibold'>6 Hours</span>
                  </div>
                  {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-primary text-primary'>
                      Fri
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Text */}
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                      7:00 AM - 3:00 PM
                    </a>
                    <span className='text-muted fw-semibold'>8 Hours</span>
                  </div>
                  {/* end::Text */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label bg-light-danger text-danger'>
                      Sat
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Text */}
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                      Rest day
                    </a>
                    <span className='text-muted fw-semibold'>No work</span>
                  </div>
                  {/* end::Text */}
                </div>
                {/* end::Item */}
                
                
              </div>
              {/* end::Body */}
            </div>
          </div>
          {/* end::Col */}
    
          {/* begin::Col */}
          <div className='col-xl-8'>
            <div className={`card card-xl-stretch mb-5 mb-xl-8`}>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Despatch ETAs</span>
                  <span className='text-muted mt-1 fw-semibold fs-7'>Estimated time of arrival of cargos</span>
                </h3>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body'>
                <div className='tab-content'>
                  {/* begin::Tap pane */}
                  <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-0'>
                            <th className='min-w-150px'>Unit</th>
                            <th className='min-w-140px'>ETA</th>
                            <th className='min-w-110px'>Status</th>
                            <th className='min-w-50px'>Actions</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          <tr>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Truck AGV-456
                              </a>
                              <span className='text-muted fw-semibold d-block'>Wool and silk</span>
                            </td>
                            <td className='text-start text-muted fw-semibold'>7:00 AM - 8:00 AM</td>
                            <td className='text-start'>
                              <span className='badge badge-light-success'>Delivered</span>
                            </td>
                            <td className='text-start'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Van ABC-123
                              </a>
                              <span className='text-muted fw-semibold d-block'>Office documents</span>
                            </td>
                            <td className='text-start text-muted fw-semibold'>1:00 PM - 3:00 PM</td>
                            <td className='text-start'>
                              <span className='badge badge-light-info'>Traveling</span>
                            </td>
                            <td className='text-start'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Truck FSD-687
                              </a>
                              <span className='text-muted fw-semibold d-block'>Manufacturing supplies</span>
                            </td>
                            <td className='text-start text-muted fw-semibold'>3:00 PM - 5:00 PM</td>
                            <td className='text-start'>
                              <span className='badge badge-light-primary'>Picked up</span>
                            </td>
                            <td className='text-start'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Truck VLS-162
                              </a>
                              <span className='text-muted fw-semibold d-block'>Finished products</span>
                            </td>
                            <td className='text-start text-muted fw-semibold'>1:00 PM - 2:00 PM</td>
                            <td className='text-start'>
                              <span className='badge badge-light-danger'>Pulled out</span>
                            </td>
                            <td className='text-start'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Truck XDF-254
                              </a>
                              <span className='text-muted fw-semibold d-block'>Cotton</span>
                            </td>
                            <td className='text-start text-muted fw-semibold'>5:00 PM - 7:00 PM</td>
                            <td className='text-start'>
                              <span className='badge badge-light-warning'>On hold</span>
                            </td>
                            <td className='text-start'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        {/* end::Table body */}
                      </table>
                    </div>
                    {/* end::Table */}
                  </div>
                  {/* end::Tap pane */}
                  {/* begin::Tap pane */}
                  <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-0'>
                            <th className='p-0 w-50px'></th>
                            <th className='p-0 min-w-150px'></th>
                            <th className='p-0 min-w-140px'></th>
                            <th className='p-0 min-w-110px'></th>
                            <th className='p-0 min-w-50px'></th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          <tr>
                            <td>
                              <div className='symbol symbol-45px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Brad Simmons
                              </a>
                              <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                            </td>
                            <td className='text-end text-muted fw-semibold'>React, HTML</td>
                            <td className='text-end'>
                              <span className='badge badge-light-success'>Approved</span>
                            </td>
                            <td className='text-end'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='symbol symbol-45px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Popular Authors
                              </a>
                              <span className='text-muted fw-semibold d-block'>Most Successful</span>
                            </td>
                            <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                            <td className='text-end'>
                              <span className='badge badge-light-warning'>In Progress</span>
                            </td>
                            <td className='text-end'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='symbol symbol-45px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Active Customers
                              </a>
                              <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                            </td>
                            <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                            <td className='text-end'>
                              <span className='badge badge-light-danger'>Rejected</span>
                            </td>
                            <td className='text-end'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        {/* end::Table body */}
                      </table>
                    </div>
                    {/* end::Table */}
                  </div>
                  {/* end::Tap pane */}
                  {/* begin::Tap pane */}
                  <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-0'>
                            <th className='p-0 w-50px'></th>
                            <th className='p-0 min-w-150px'></th>
                            <th className='p-0 min-w-140px'></th>
                            <th className='p-0 min-w-110px'></th>
                            <th className='p-0 min-w-50px'></th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          <tr>
                            <td>
                              <div className='symbol symbol-45px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Bestseller Theme
                              </a>
                              <span className='text-muted fw-semibold d-block'>Best Customers</span>
                            </td>
                            <td className='text-end text-muted fw-semibold'>ReactJS, Ruby</td>
                            <td className='text-end'>
                              <span className='badge badge-light-warning'>In Progress</span>
                            </td>
                            <td className='text-end'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='symbol symbol-45px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Active Customers
                              </a>
                              <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                            </td>
                            <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                            <td className='text-end'>
                              <span className='badge badge-light-danger'>Rejected</span>
                            </td>
                            <td className='text-end'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='symbol symbol-45px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                New Users
                              </a>
                              <span className='text-muted fw-semibold d-block'>Awesome Users</span>
                            </td>
                            <td className='text-end text-muted fw-semibold'>Laravel,Metronic</td>
                            <td className='text-end'>
                              <span className='badge badge-light-primary'>Success</span>
                            </td>
                            <td className='text-end'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='symbol symbol-45px me-2'>
                                <span className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                                    className='h-50 align-self-center'
                                    alt=''
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                Popular Authors
                              </a>
                              <span className='text-muted fw-semibold d-block'>Most Successful</span>
                            </td>
                            <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                            <td className='text-end'>
                              <span className='badge badge-light-warning'>In Progress</span>
                            </td>
                            <td className='text-end'>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        {/* end::Table body */}
                      </table>
                    </div>
                    {/* end::Table */}
                  </div>
                  {/* end::Tap pane */}
                </div>
              </div>
              {/* end::Body */}
            </div>
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}
      </>
    )
  }
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
