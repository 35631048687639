import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { useAuth } from '../modules/auth'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/administration/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/administration/user-management/UsersPage'))
  const UserRoles = lazy(() => import('../modules/administration/user-roles/UserRolesPage'))
  const UsersTimesheets = lazy(() => import('../modules/administration/users-timesheets/UsersTimeheets'))
  const SafetyPage = lazy(() => import('../modules/safety/SafetyPage'))
  const HelpPage = lazy(() => import('../modules/help/HelpPage'))
  const LeavePage = lazy(() => import('../modules/leave/LeavePage'))
  const ProductsPage = lazy(() => import('../modules/products/ProductsPage'))
  const HomePage = lazy(() => import('../modules/home/HomePage'))

  const { currentUser } = useAuth()

  let defaultNavigateTo = '/dashboard'

  if (currentUser?.roles.find(indivRole => indivRole.id === 1)) {
    defaultNavigateTo = '/administration/user-management/users'
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={defaultNavigateTo} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='administration/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='administration/users-timesheets/*'
          element={
            <SuspensedView>
              <UsersTimesheets />
            </SuspensedView>
          }
        />
        <Route
          path='administration/user-roles/*'
          element={
            <SuspensedView>
              <UserRoles />
            </SuspensedView>
          }
        />
        <Route
          path='application/home/*'
          element={
            <SuspensedView>
              <HomePage />
            </SuspensedView>
          }
        />
        <Route
          path='application/safety/*'
          element={
            <SuspensedView>
              <SafetyPage />
            </SuspensedView>
          }
        />
        <Route
          path='application/help/*'
          element={
            <SuspensedView>
              <HelpPage />
            </SuspensedView>
          }
        />
        <Route
          path='administration/leaves/*'
          element={
            <SuspensedView>
              <LeavePage />
            </SuspensedView>
          }
        />
        <Route
          path='administration/products/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
